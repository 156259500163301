/* eslint-disable react/prop-types */
import React from 'react'
import { graphql } from 'gatsby'

import PageHeader from '../components/PageHeader'
import Content from '../components/Content.js'
import Layout from '../components/Layout.js'

// Export Template for use in CMS preview
const AboutPageTemplate = ({ data }) => {
  const { title, subTitle, featuredImage, body } = data.directus.aboutPage
  return (
    <main>
      <PageHeader
        title={title}
        subtitle={subTitle}
        backgroundImage={featuredImage}
      />
      <section className="section">
        <div className="container">
          <Content body={body} />
        </div>
      </section>
    </main>
  )
}

const AboutPage = ({ data }) => (
  <Layout meta={data.directus.aboutPage.SEO || false}>
    <AboutPageTemplate data={data} />
  </Layout>
)

export default AboutPage

export const pageQuery = graphql`
  query aboutPage {
    directus {
      aboutPage {
        title
        subTitle
        featuredImage {
          id
        }
        body
      }
    }
  }
`
